export default {
  state: {
    defaultModalData: {
      data: null,
      type: "CREATE",
      status: false,
      componentName: "",
    },

    viewDataBISistems: {
      status: false,
      data: {},
    },
  },

  getters: {
    getDefaultModalData(state) {
      return state.defaultModalData;
    },

    getViewDataBISistems(state) {
      return state.viewDataBISistems;
    },
  },

  mutations: {
    setDefaultModalInfos(state, infos) {
      state.defaultModalData.data = infos.data;
      state.defaultModalData.type = infos.data === null ? "CREATE" : "EDIT";
      state.defaultModalData.status = infos.status;
      state.defaultModalData.componentName = infos.componentName;
    },

    setViewDataBISistems(state, infos) {
      state.viewDataBISistems = {
        status: infos.status,
        data: infos.data !== null ? infos.data : {},
      };
    },
  },

  actions: {
    changeDefaultModalInfos(context, infos) {
      context.commit("setDefaultModalInfos", infos);
    },

    changeViewDataModal(context, infos) {
      context.commit("setViewDataBISistems", infos);
    },
  },
};
