export default {
  state: {
    allAbilities: [],
    selectedProfile: {},
    profileAbilitiesData: [],
    selectedProfileAbilitiesId: [],
  },

  getters: {
    getSystemAbilities(state) {
      return state.allAbilities;
    },

    getSelectedProfile(state) {
      return state.selectedProfile;
    },

    getProfileAbilities(state) {
      return state.profileAbilitiesData;
    },

    getSelectedProfileAbilitiesId(state) {
      return state.selectedProfileAbilitiesId;
    },
  },

  mutations: {
    setSystemAbilities(state, abilities) {
      state.allAbilities = abilities;
    },

    setSelectedProfile(state, item) {
      state.selectedProfile = item;
    },

    setProfileAbilitiesInfo(state, abilities) {
      state.profileAbilitiesData.push(abilities);
    },

    setResetProfileAbilitiesInfo(state) {
      state.profileAbilitiesData = [];
    },

    setSelectedProfileAbilitiesId(state, id) {
      state.selectedProfileAbilitiesId = id;
    },
  },

  actions: {
    changeSystemAbilities(context, abilities) {
      context.commit("setSystemAbilities", abilities);
    },

    changeSelectedProfile(context, item) {
      context.commit("setSelectedProfile", item);
    },

    changeProfileAbilitiesInfo(context, abilities) {
      context.commit("setProfileAbilitiesInfo", abilities);
    },

    resetProfileAbilitiesInfo(context) {
      context.commit("setResetProfileAbilitiesInfo");
    },

    changeSelectedProfileAbilitiesId(context, id) {
      context.commit("setSelectedProfileAbilitiesId", id);
    },
  },
};
