export default {
  state: {
    userData: {},
  },

  getters: {
    getUserData(state) {
      return state.userData;
    },
  },

  mutations: {
    setUserData(state, data) {
      const isNull = data === null;
      let isExistArea = false;

      if (!isNull) {
        isExistArea = data.area_users.length > 0;
      }

      state.userData = {
        id: !isNull ? data.id : "",
        name: !isNull ? data.name : "",
        email: !isNull ? data.email : "",
        cpf: !isNull ? data.cpf : "",
        profile: !isNull ? parseInt(data.profile_id) : "",
        superintendence_unity: !isNull
          ? parseInt(data.superintendence_unity_id)
          : "",
        area: isExistArea ? parseInt(data.area_users[0].area_id) : "",
        phone: !isNull ? data.phone : "",
        phoneExtension: !isNull ? data.phone_extension : "",
      };
    },
  },

  actions: {
    changeUserData(context, infos) {
      context.commit("setUserData", infos);
    },
  },
};
