<template>
  <v-dialog v-model="$modal" width="550" persistent>
    <v-card class="card">
      <vue2-lottie
        :options="defaultOptions"
        :height="100"
        :width="100"
        v-on:animCreated="handleAnimation"
      />

      <h1 class="mb-4">Oops...</h1>

      <span>
        Resultado indisponível por conta das múltiplas requisições em pouco
        tempo. Aguarde e tente novamente.
      </span>

      <div class="timer">
        <v-icon>mdi-clock-outline</v-icon>
        <span>
          {{ Math.floor(timer / 60) }}:{{
            timer % 60 < 10 ? "0" + (timer % 60) : timer % 60
          }}
        </span>
      </div>

      <v-btn
        v-if="timer === 0"
        class="border__radius-15 mt-2"
        color="primary"
        height="44"
        depressed
        @click="$modal = false"
      >
        CONTINUAR
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
import animationData from "@/assets/animations/wrong-file.json";

export default {
  name: "ModalManyAttempsA",

  data: () => ({ timer: 60, defaultOptions: { animationData } }),

  watch: {
    $modal(val) {
      if (val) {
        this.startTimer();
      } else {
        this.timer = 60;
      }
    },
  },

  methods: {
    startTimer() {
      setInterval(() => {
        if (this.timer > 0) {
          this.timer--;
        }
      }, 1000);
    },

    handleAnimation(anim) {
      this.anim = anim;
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 25px;
  text-align: center;

  .timer {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px;
  }
}
</style>
