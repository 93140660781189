export default {
  state: {
    responsableData: {},
  },

  getters: {
    getResponsableData(state) {
      return state.responsableData;
    },
  },

  mutations: {
    setResponsableData(state, data) {
      const isNull = data === null;

      state.responsableData = {
        id: !isNull ? data.id : "",
        name: !isNull ? data.name : "",
        email: !isNull ? data.email : "",
        area: !isNull ? data.area.id : "",
        superintendence_unity: !isNull
          ? data.area.superintendence_unity.id
          : "",
        phone: !isNull ? data.phone : "",
        phoneExtension: !isNull ? data.phone_extension : "",
      };
    },
  },

  actions: {
    changeResponsableData(context, infos) {
      context.commit("setResponsableData", infos);
    },
  },
};
