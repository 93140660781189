export default {
  state: {
    captchaNumbers: "",
  },

  getters: {
    getCaptchaNumbers(state) {
      return state.captchaNumbers;
    },
  },

  mutations: {
    setCaptchaNumbers(state, data) {
      state.captchaNumbers = data;
    },
  },

  actions: {
    changeCaptchaNumbers(context, data) {
      context.commit("setCaptchaNumbers", data);
    },
  },
};
