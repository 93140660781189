export default {
  state: {
    sidebar: {
      status: null,
      componentName: "",
    },
  },

  getters: {
    getSidebarRigthData(state) {
      return state.sidebar;
    },
  },

  mutations: {
    setSidebarRigthData(state, infos) {
      state.sidebar = {
        status: infos.status,
        componentName: infos.componentName,
      };
    },
  },

  actions: {
    changeSidebarRigthData(context, infos) {
      context.commit("setSidebarRigthData", infos);
    },
  },
};
