export default {
  state: {
    superintendenceUnityData: {},
  },

  getters: {
    getSuperintendeceUnityData(state) {
      return state.superintendenceUnityData;
    },
  },

  mutations: {
    setSuperintendeceUnityData(state, data) {
      const isNull = data === null;

      state.superintendenceUnityData = {
        id: !isNull ? data.id : "",
        name: !isNull ? data.name : "",
        abbreviation: !isNull ? data.abbreviation : "",
        typeSuperintendenceUnity: !isNull
          ? parseInt(data.type_unity_superintendence_id)
          : "",
      };
    },
  },

  actions: {
    changeSuperintendeceUnityData(context, infos) {
      context.commit("setSuperintendeceUnityData", infos);
    },
  },
};
