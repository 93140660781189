const setDateAndHour = () => {
  const dateNow = new Date();

  const day = dateNow.getDate();
  const month = dateNow.getMonth() + 1;
  const year = dateNow.getFullYear();

  const formattedDate = day + "/" + month + "/" + year;

  const hour = dateNow.getHours();
  const mins = dateNow.getMinutes();

  const hourConvertedToMinutes = hour * 3600 + mins * 60;

  return { formattedDate, hourConvertedToMinutes };
};

export default setDateAndHour;
