export default {
  state: {
    newsData: {},
    newsModalInHome: false,
  },

  getters: {
    getNewsData(state) {
      return state.newsData;
    },

    getNewsModalInHome(state) {
      return state.newsModalInHome;
    },
  },

  mutations: {
    setNewsData(state, data) {
      state.newsData = {
        id: data ? data.id : null,
        type: data ? "EDIT" : "CREATE",
        categories: data ? data.category_informatives : [],
        themes: data ? data.themes : [],
        initialDate: data ? data.initial_date : null,
        endDate: data ? data.end_date : null,
        file: [],
        selectedFile: data ? data.file : null,
        image: [],
        selectedImage: data ? data.image : null,
        url: data ? data.url : null,
        title: data ? data.title : null,
        body: data ? data.body : null,
        userId: data ? data.area : null,
        user: data ? data.user : null,
      };
    },

    setNewsModalInHome(state, status) {
      state.newsModalInHome = status;
    },
  },

  actions: {
    changeNewsData(context, data) {
      context.commit("setNewsData", data);
    },

    changeNewsModalInHome(context, status) {
      context.commit("setNewsModalInHome", status);
    },
  },
};
