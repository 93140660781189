export default {
  state: {
    bannerModal: false,
    isEmpty: false,
  },

  getters: {
    getBannerLayout(state) {
      return state.bannerLayout;
    },
  },

  mutations: {
    setBannerLayout(state, status) {
      state.bannerLayout = status;
    },
  },

  actions: {
    changeBannerLayout(context, status) {
      context.commit("setBannerLayout", status);
    },
  },
};
