export default {
  state: {
    snackbar: {
      status: false,
      type: "",
      title: "",
      message: "",
    },
  },

  getters: {
    getSnackbarData(state) {
      return state.snackbar;
    },
  },

  mutations: {
    setSnackbarData(state, data) {
      state.snackbar = {
        status: data[0],
        type: data[0] ? data[1] : "",
        title: data[0] ? data[2] : "",
        message: data[0] ? data[3] : "",
      };
    },
  },

  actions: {
    changeSnackbarData(context, data) {
      context.commit("setSnackbarData", data);
    },
  },
};
