export default {
  state: {
    passwordInfos: [],
    passwordStep: "SendEmail",
  },

  getters: {
    getPasswordInfos(state) {
      return {
        data: state.passwordInfos,
        step: state.passwordStep,
      };
    },
  },

  mutations: {
    setPasswordInfos(state, infos) {
      if (infos.data !== undefined) {
        infos.data !== null
          ? state.passwordInfos.push(infos.data)
          : (state.passwordInfos = []);
      }

      state.passwordStep = infos.step;
    },
  },

  actions: {
    changePasswordInfos(context, infos) {
      context.commit("setPasswordInfos", infos);
    },
  },
};
