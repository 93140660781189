export default {
  state: {
    areaData: {},
    areaContactData: {},
    contactModalStatus: false,
  },

  getters: {
    getAreaData(state) {
      return state.areaData;
    },

    getAreaContactData(state) {
      return state.areaContactData;
    },

    getContactModalStatus(state) {
      return state.contactModalStatus;
    },
  },

  mutations: {
    setAreaData(state, data) {
      const isNull = data === null;

      state.areaData = {
        id: !isNull ? data.id : "",
        name: !isNull ? data.name : "",
        abbreviation: !isNull ? data.abbreviation : "",
        phone: !isNull ? data.phone : "",
        superintendence_unity: !isNull
          ? parseInt(data.superintendence_unity_id)
          : "",
      };
    },

    setAreaContactData(state, data) {
      const isNull = data === null;

      state.areaContactData = {
        id: !isNull ? data.id : "",
        name: !isNull ? data.name : "",
        phone_extension: !isNull ? data.phone_extension : "",
        area_id: !isNull ? data.area_id : "",
      };
    },

    setContactModalStatus(state, status) {
      state.contactModalStatus = status;
    },
  },

  actions: {
    changeAreaData(context, infos) {
      context.commit("setAreaData", infos);
    },

    changeAreaContactData(context, infos) {
      context.commit("setAreaContactData", infos);
    },

    changeContactModalStatus(context, status) {
      context.commit("setContactModalStatus", status);
    },
  },
};
