export default {
  state: {
    tabActive: 0,
  },

  getters: {
    getAccountTabStatus(state) {
      return state.tabActive;
    },
  },

  mutations: {
    setAccountTabStatus(state, status) {
      state.tabActive = status;
    },
  },

  actions: {
    changeAccountTabStatus(context, status) {
      context.commit("setAccountTabStatus", status);
    },
  },
};
