export default {
  state: {
    userFavorities: [],
    moreInfos: {},
    filters: {},
    totalBIAndSystems: 0,
  },

  getters: {
    getUserFavorities(state) {
      return state.userFavorities;
    },

    getMoreInfos(state) {
      return state.moreInfos;
    },

    getDashboardFilters(state) {
      return state.filters;
    },

    getTotalBIAndSystems(state) {
      return state.totalBIAndSystems;
    },
  },

  mutations: {
    setUserFavorities(state, data) {
      if (state.userFavorities.length > 0) {
        state.userFavorities = [];
        state.userFavorities = data;
      } else {
        state.userFavorities = data;
      }
    },

    setMoreInfos(state, data) {
      const isNull = data === null;

      state.moreInfos = {
        id: !isNull ? data.id : "",
        name: !isNull ? data.name : "",
        abbreviation: !isNull ? data.abbreviation : "",
        link: !isNull ? data.link : "",
        image: !isNull ? data.image : "",
        category: !isNull ? data.category : "",
        type_bi_system: !isNull ? data.type_bi_system : "",
        visibility: !isNull ? data.visibility : "",
        superintendence_unities: !isNull ? data.superintendence_unities : "",
        areas: !isNull ? data.areas : "",
        responsables: !isNull ? data.responsibles : "",
        description: !isNull ? data.description : "",
        status: !isNull ? data.deleted_at : "",
      };
    },

    setDashboardFilters(state, data) {
      state.filters = data;
    },

    setTotalBIAndSystems(state, data) {
      state.totalBIAndSystems = data;
    },
  },

  actions: {
    changeUserFavorities(context, infos) {
      context.commit("setUserFavorities", infos);
    },

    changeMoreInfos(context, data) {
      context.commit("setMoreInfos", data);
    },

    changeDashboardFilters(context, data) {
      context.commit("setDashboardFilters", data);
    },

    changeTotalBIAndSystems(context, data) {
      context.commit("setTotalBIAndSystems", data);
    },
  },
};
