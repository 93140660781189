import Modal from "./ManyAttemps.vue";

export default {
  install: (Vue) => {
    Vue.mixin({
      data: () => ({
        modalData: false,
      }),
    });

    Object.defineProperty(Vue.prototype, "$modal", {
      get() {
        return this.$root.modalData;
      },

      set(status) {
        this.$root.modalData = status;
      },
    });

    Vue.component("ModalManyAttemps", Modal);
  },
};
