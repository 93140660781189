export default {
  state: {
    searchData: {
      type: "",
      search: "",
    },

    actionsData: [],
    updateGrid: false,
  },

  getters: {
    getSearchData(state) {
      return state.searchData;
    },

    getActionsData(state) {
      return state.actionsData;
    },

    getUpdateGrid(state) {
      return state.updateGrid;
    },
  },

  mutations: {
    setSearchData(state, data) {
      state.searchData = {
        type: data.type,
        search: data.search,
      };
    },

    setActionsData(state, data) {
      state.actionsData = data;
    },

    setUpdateGridStatus(state, status) {
      state.updateGrid = status;
    },

    setClearSearchData(state) {
      state.searchData.search = "";
    },
  },

  actions: {
    changeSearchData(context, data) {
      context.commit("setSearchData", data);
    },

    changeActionsData(context, data) {
      context.commit("setActionsData", data);
    },

    changeUpdateGridStatus(context, status) {
      context.commit("setUpdateGridStatus", status);
    },

    changeClearSimpleSearchData(context) {
      context.commit("setClearSearchData");
    },
  },
};
