export default {
  state: {
    localRoutes: [],
  },

  getters: {
    getLocalRoutes(state) {
      return state.localRoutes;
    },
  },

  mutations: {
    setLocalRoutes(state, routes) {
      state.localRoutes = routes;
    },
  },

  actions: {
    changeLocalRoutes(context, routes) {
      context.commit("setLocalRoutes", routes);
    },
  },
};
