import Vue from "vue";
import Vuex from "vuex";

import notifications from "./modules/widgets/notifications";
import grids from "./modules/widgets/grids";
import modals from "./modules/widgets/modals";
import sidebarRight from "./modules/widgets/sidebarRight";

import routes from "./modules/routesModule";
import captcha from "./modules/captchaModule";
import password from "./modules/passwordModule";
import account from "./modules/accountModule";
import user from "./modules/userModule";
import profiles from "./modules/profilesModule";
import area from "./modules/areaModule";
import biSystems from "./modules/biSystemsModule";
import superintendeceUnity from "./modules/superintendeceUnityModule";
import category from "./modules/categoryModule";
import dashboard from "./modules/dashboardModule";
import responsable from "./modules/responsablesModule";
import home from "./modules/homeModule";
import news from "./modules/newsModule";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    // WIDGETS
    notifications,
    grids,
    modals,
    sidebarRight,

    // MODULES
    routes,
    captcha,
    password,
    account,
    user,
    profiles,
    area,
    biSystems,
    superintendeceUnity,
    category,
    dashboard,
    responsable,
    home,
    news,
  },
});
