export default {
  state: {
    biSystemsData: {
      id: "",
      name: "",
      abbreviation: "",
      description: "",
      link: "",
      visibility: "",
      superintendenceUnity: [],
      areas: [],
      responsables: [],
      type_bi_system: "",
      category: "",
      img: [],
    },
  },

  getters: {
    getBiSystemsData(state) {
      return state.biSystemsData;
    },
  },

  mutations: {
    setBiSystemsData(state, data) {
      const isNull = data === null;

      let superintendenceUnityArray = [];
      let areasArray = [];
      let responsablesUnityArray = [];

      if (!isNull) {
        superintendenceUnityArray = [];
        areasArray = [];
        responsablesUnityArray = [];

        if (data.superintendence_unities.length > 0) {
          data.superintendence_unities.forEach((element) => {
            superintendenceUnityArray.push(element.id);
          });
        }

        if (data.areas.length > 0) {
          data.areas.forEach((element) => {
            areasArray.push(element.id);
          });
        }

        if (data.responsibles.length > 0) {
          data.responsibles.forEach((element) => {
            responsablesUnityArray.push(element.id);
          });
        }
      }

      state.biSystemsData = {
        id: !isNull ? data.id : "",
        name: !isNull ? data.name : "",
        abbreviation: !isNull ? data.abbreviation : "",
        description: !isNull ? data.description : "",
        link: !isNull ? data.link : "",
        visibility: !isNull ? parseInt(data.visibility_id) : "",
        superintendenceUnity: superintendenceUnityArray,
        areas: areasArray,
        responsables: responsablesUnityArray,
        type_bi_system: !isNull ? parseInt(data.type_bi_system_id) : "",
        category: !isNull ? parseInt(data.visibility_id) : "",
        img: !isNull ? data.image : [],
      };
    },
  },

  actions: {
    changeBiSystemsData(context, infos) {
      context.commit("setBiSystemsData", infos);
    },
  },
};
