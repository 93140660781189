import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index";
import guard from "@/guard/routeGuard.js";
const { validationRoute } = guard;

Vue.use(VueRouter);

const routes = [
  // HOME
  {
    path: "/",
    component: () => import("@/views/ViewWithoutLayout.vue"),
    children: [
      {
        path: "",
        name: "Home",
        component: () => import("@/components/modules/home/Home.vue"),
      },
    ],
  },

  // DASHBOARD
  {
    path: "/dashboard",
    beforeEnter: validationRoute,
    component: () => import("@/views/ViewWithLayout.vue"),
    children: [
      {
        path: "",
        name: "Dashboard",
        permission: "dashboard",
        component: () => import("@/components/modules/dashboard/Dashboard.vue"),
      },
    ],
  },

  // CAD. BÁSICOS
  // ÁREA
  {
    path: "/areas",
    beforeEnter: validationRoute,
    component: () => import("@/views/ViewWithLayout.vue"),
    children: [
      {
        path: "",
        name: "Lista de áreas",
        permission: "cad_area",
        component: () => import("@/components/modules/areas/Grid.vue"),
      },
      {
        path: "/area/ramais",
        name: "Lista de ramais da área",
        permission: "cad_area",
        component: () => import("@/components/modules/areas/contacts/Grid.vue"),
      },
    ],
  },

  // BI/SISTEMAS
  {
    path: "/bi-sistemas",
    beforeEnter: validationRoute,
    component: () => import("@/views/ViewWithLayout.vue"),
    children: [
      {
        path: "",
        name: "Lista de BI/Sitemas",
        permission: "cad_dashboard_system",
        component: () => import("@/components/modules/bi-systems/Grid.vue"),
      },
    ],
  },

  // CATEGORIAS
  {
    path: "/categorias",
    beforeEnter: validationRoute,
    component: () => import("@/views/ViewWithLayout.vue"),
    children: [
      {
        path: "",
        name: "Lista de categoria",
        permission: "cad_dashboard_system",
        component: () => import("@/components/modules/categories/Grid.vue"),
      },
    ],
  },

  // CATEGORIAS
  {
    path: "/noticias",
    beforeEnter: validationRoute,
    component: () => import("@/views/ViewWithLayout.vue"),
    children: [
      {
        path: "",
        name: "Notícias",
        permission: "list_informative",
        component: () => import("@/components/modules/news/Index.vue"),
      },
    ],
  },

  // PERFIS
  {
    path: "/perfis",
    beforeEnter: validationRoute,
    component: () => import("@/views/ViewWithLayout.vue"),
    children: [
      {
        path: "",
        name: "Lista de perfis",
        permission: "list_perfil",
        component: () => import("@/components/modules/profiles/Grid.vue"),
      },
    ],
  },

  // PERFIS
  {
    path: "/responsaveis",
    beforeEnter: validationRoute,
    component: () => import("@/views/ViewWithLayout.vue"),
    children: [
      {
        path: "",
        name: "Lista de responsáveis",
        permission: "list_perfil",
        component: () => import("@/components/modules/responsables/Grid.vue"),
      },
    ],
  },

  // SUPERINTENDÊNCIAS
  {
    path: "/superintendencias-unidades",
    beforeEnter: validationRoute,
    component: () => import("@/views/ViewWithLayout.vue"),
    children: [
      {
        path: "",
        name: "Lista de superintendências",
        permission: "list_sup_unit",
        component: () =>
          import("@/components/modules/superintendences-unities/Grid.vue"),
      },
    ],
  },

  // USUARIOS
  {
    path: "/usuarios",
    beforeEnter: validationRoute,
    component: () => import("@/views/ViewWithLayout.vue"),
    children: [
      {
        path: "",
        name: "Lista de usuários",
        permission: "list_usuario",
        component: () => import("@/components/modules/users/Grid.vue"),
      },
    ],
  },

  // MINHA CONTA
  {
    path: "/minha-conta",
    beforeEnter: validationRoute,
    component: () => import("@/views/ViewWithLayout.vue"),
    children: [
      {
        path: "",
        name: "Minha Conta",
        permission: "account",
        component: () => import("@/components/modules/my-account/Account.vue"),
      },
    ],
  },

  // RESETAR SENHA
  {
    path: "/esqueci-minha-senha",
    component: () => import("@/views/ViewWithoutLayout.vue"),
    children: [
      {
        path: "",
        name: "Esqueci minha senha",
        component: () => import("@/components/modules/password/Password.vue"),
      },
    ],
  },
];

store.state.localRoutes = routes;

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

export default router;
